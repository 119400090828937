@font-face {
    font-family: Raleway;
    src: url('./pages/fonts/Raleway/Raleway-VariableFont_wght.ttf') format('truetype');
    font-weight: 100 900;
}

.nav {
    font-family: Raleway;
}

.loink {
    font-family: Raleway;
}

.p {
    margin: 0;
    padding: 0;
}
.transition {
    transition: ease-in-out 0.3s;
}

.divvy:hover {
    cursor: pointer;
}