@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: QuickSand;
    src: url(./pages/menu/Quicksand/Quicksand-VariableFont_wght.ttf) format('truetype');
  }
}

@layer components {
  .btn-blue {
    @apply py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
  }
  
}

/* @layer components {
  .nunm1 {
      @apply py-8 px-8 max-w-sm mx-auto rounded-xl font-black font-sans;
      @apply shadow-md space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6;
  } 

  .btn-1 {
      @apply px-4 py-1 text-sm text-purple-100 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2 font-semibold font-sans rounded-full border border-purple-100 hover:text-gray-200 hover:bg-purple-600;
  } 

} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


