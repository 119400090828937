@font-face {
    font-family: Quicksand;
    src: url('./Quicksand/Quicksand-VariableFont_wght.ttf') format('truetype');
    font-weight: 100 900;
}

.font-sand {
    font-family: QuickSand;
}

.one {
    position: relative;
}

.three {
    position: absolute;
    z-index: 1;
    background-color: yellow;
}

.two {
    position: relative;
    z-index: 0;
    opacity: 0.1;
}

.three:hover {
    z-index: 0;
}

.two:hover {
    z-index:1;
    opacity: 1;
    transition: opacity 100ms ease-in-out ;
}

/* .content {
    width: 60vh;
*/
.pg-0 {
    height: 75vh;
    /* width: 100vw; */
    margin-left: auto;
    margin-right: auto;
}


.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

.pg-1 {
    height: 50vh;
    width: 50vw;
}

.button-click {
    transition: background 0.2s ease-in-out;
    font-family: QuickSand;
    font-weight: 800;
}

@media screen and (max-width:360px) {
    .pg-1 {
        width:100vw;
    }
} 

.cardHeight {
    height: 40vh;
}

.pp {
    position: relative;
}

.gae {
    position: absolute;
    bottom: 0;
    right: 0;
}

@media screen and (max-width: 360px) {
    .boi {
        height: 100vh;
    }
}

.changelog:hover {
    opacity: 0.8;
}

.changelog h1 {

color: yellow;
margin: auto;
}

.paragraph {
    color: rgb(211, 212, 99);
    transition-property: max-height;
    transition-duration: 500ms;
    transition-timing-function: ease;
    max-height: 0;
    overflow: hidden;
    margin-bottom: 0;
}


.changelog:hover .paragraph {
    transition-property: max-height;
    transition-duration: 500ms;
    transition-timing-function: ease;
    max-height: 20em;
    overflow: scroll;
}

.down {
    transition: transform 300ms ease;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.changelog:hover .down {
    transition: transform 300ms ease;
    transform: rotate(-135deg);
}

.arrow {
    border: solid rgb(255, 255, 255);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}
