.App {
  text-align: center;
  overflow: hidden;
  background-color:rgb(51, 51, 54)
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.containerboi {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-header {
  background-color: transparent;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  background-color: rgb(100, 100, 163);
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-corner {
  color:  rgb(53,56,49);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to top, rgb(29, 29, 27), rgb(53,56,49));
  border-radius: 10px;
  border: rgb(52, 77, 105) solid 0px;
}

@keyframes buttonHover {
  0%   { width: 500px;}
  100%   { width: 600px;}
}

