@font-face {
    font-family: Raleway;
    src: url(../fonts/Raleway/Raleway-VariableFont_wght.ttf) format('truetype');
    font-weight: 100 900;
}
/* 
.forums{
    height: 100vh;
} */

h1 {
    font-family: Raleway;
}